import PropTypes from 'prop-types'
import React from 'react'
import Navbar from './navbar'

const Header = ({ siteMetadata }) => <Navbar siteMetadata={siteMetadata} />

Header.propTypes = {
  siteMetadata: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    menuLinks: PropTypes.array,
    categoryLinks: PropTypes.array,
  }),
}

Header.defaultProps = {
  siteMetadata: PropTypes.shape({
    title: '',
    url: '',
    menuLinks: [],
    categoryLinks: [],
  }),
}

export default Header
