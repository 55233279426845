import React from "react";

import Layout from "../components/layout";

const NotFoundPage = ({pageContext}) => (
  <Layout buildTime = {pageContext.buildTime}>
    <h1>La page que vous avez demandée n'existe pas</h1>
  </Layout>
);

export default NotFoundPage;
