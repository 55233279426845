import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import logo from "../../images/logo.png";
import "../../scss/components/categoryNavbar.scss";

const iStyle = {
  fontSize: "0.8rem",
  color: "black",
  fontWeight: "bold",
  textTransform: "uppercase",
};

const SubMenuNav = ({ items }) => {
  //console.log(items);
  const submenu = items.map((item) => {
    const href = item.link;
    const title = item.name;
    return (
      <Link key={href} className="dropdown-item" to={href}>
        <span style={iStyle}>{title}</span>
      </Link>
    );
  });
  return <>{submenu} </>;
};

const menuNav = (list) => {
  return list.map((item) => {
    const href = item.link || undefined;
    const title = item.name;
    //console.log(item);
    if (item.items == null) {
      return (
        <li key={`${title}_menu`} className="navbar-item dropdown rounded">
          <Link className="nav-link" to={href}>
            <span style={iStyle}>{title}</span>
          </Link>
        </li>
      );
    }
    return (
      <li
        key={`${title}_menu`}
        className="nav-item dropdown navbar-item dropdown-hoverable"
      >
        <div
          className="nav-link"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span style={iStyle}>{title}</span>
        </div>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <SubMenuNav items={item.items} />
        </div>
      </li>
    );
  });
};

const HomeLi = () => {
  return (
    <li key={`accueil_menu`} className="navbar-item dropdown rounded">
      <Link className="nav-link" to="/">
        <span style={iStyle}>{"Accueil"}</span>
      </Link>
    </li>
  );
};

const NaviguationBar = ({ siteMetadata }) => {
  const tStyle = {
    fontSize: "1.1rem",
    marginLeft: "3px",
    fontWeight: "bold",
    color: "black",
  };
  const subtStyle = {
    fontSize: "0.6rem",
    marginLeft: "3px",
    color: "black",
  };
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);
  const show = showCollapsedMenu ? "show" : "";
  //console.log(show);
  return (
    <>
      <nav className="menu-navbar navbar navbar-expand-md fixed-top bg-white p-1 ">
        <div className="container">
          <Link
            className="d-flex align-items-center  justify-content-center"
            to="/"
          >
            <div className="p-0">
              <img alt="" src={logo} width="45" height="45" />
            </div>

            <div className="p-0" style={{ width: "89%" }}>
              <div style={tStyle}>{siteMetadata.title}</div>
              <div style={subtStyle}>{siteMetadata.description}</div>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
            onClick={() => setShowCollapsedMenu(!showCollapsedMenu)}
          >
            <span className="navbar-toggler-icon" aria-label="Menu" />
          </button>

          <div
            className={"collapse navbar-collapse pl-2 " + show}
            id="collapsibleNavbar"
          >
            <ul className="navbar-nav ml-auto">
              <HomeLi />
              {menuNav(siteMetadata.menuLinks)}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

NaviguationBar.propTypes = {
  siteMetadata: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    menuLinks: PropTypes.array,
  }),
};

NaviguationBar.defaultProps = {
  siteMetadata: PropTypes.shape({
    title: "",
    url: "",
    description: "",
    menuLinks: [],
  }),
};

export default NaviguationBar;
