import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../images/logo.png';

const HomeLi = () => {
  const iStyle = {
    fontSize: '0.8rem',
    color: 'black',
  };
  return (
    <li key="accueil_menu">
      <Link to="/">
        <span style={iStyle}>Accueil</span>
      </Link>
    </li>
  );
};

const MenuLinks = ({ links }) => {
  const spanStyle = {
    fontSize: '0.8rem',
    color: 'black',
  };
  const items = links
    .filter((item) => item.items == null)
    .map((item) => {
      const itemKey = `${item.link}_footerMenu`;
      return (
        <li key={itemKey}>
          <Link to={item.link}>
            <span style={spanStyle}>{item.name}</span>
          </Link>
        </li>
      );
    });
  return (
    <ul className="list-unstyled">
      <HomeLi />
      {' '}
      {items}
    </ul>
  );
};

const CategoryLinks = ({ links }) => {
  const spanStyle = {
    fontSize: '0.8rem',
    color: 'black',
  };
  const items = links
    .filter((item) => item.items !== null)
    .map((item) => item.items.map((cat) => {
      const itemKey = `${cat.link}_footerMenu`;
      const lastChar = cat.link[cat.link.length - 1];
      if (lastChar != '/') {
        cat.link += '/';
      }
      return (
        <li key={itemKey}>
          <Link to={cat.link}>
            <span style={spanStyle}>{cat.name}</span>
          </Link>
        </li>
      );
    }));
  return <ul className="list-unstyled">{items}</ul>;
};
const FooterLinks = ({ links }) => (
  <div className="container ">
    <div className="row ">
      <div className="col-4">
        <a className=" img-responsive" href="/">
          <img src={logo} width="60" height="60" alt="Accueil" />
        </a>
      </div>
      <div className="text-left flex-column col-4">
        <MenuLinks links={links} />
      </div>
      <div className="d-flex justify-content-center text-left flex-column col-4">
        <CategoryLinks links={links} />
      </div>
    </div>
  </div>
);

const Footer = ({ siteMetadata, buildTime }) => {
  const siteTitle = siteMetadata.title;
  const { siteUrl } = siteMetadata;
  const objTime = new Date(buildTime);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  const currentTime = objTime.toLocaleDateString('fr-FR', options);
  return (
    <>
      <footer
        className="blog-footer pt-0 bg-custom-1 pb-3"
        style={{ backgroundColor: '#f2bac9', textDecoration: 'none' }}
      >
        <hr
          className="border-2 shadow"
          style={{ backgroundColor: '#eea5b8' }}
        />

        <FooterLinks links={siteMetadata.menuLinks} />
      </footer>
      <div
        className="footer-copyright text-center "
        style={{ backgroundColor: '#f7d5de' }}
      >
        <span style={{ fontSize: '0.8em' }} className="text-dark">
          {' '}
          &copy; 2023 -
          <a href={siteUrl} style={{ color: 'black' }}>
            {' '}
            {siteTitle}
            {' '}
          </a>
          - Dernière mise à jour :
          {' '}
          {currentTime}
          .
        </span>
      </div>
    </>
  );
};

Footer.propTypes = {
  siteMetadata: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    menuLinks: PropTypes.array,
  }),
  buildTime: PropTypes.string,
};

Footer.defaultProps = {
  siteMetadata: PropTypes.shape({
    title: '',
    url: '',
    menuLinks: [],
  }),
  buildTime: '',
};
export default Footer;
