/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';

const Layout = ({ title, buildTime, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site: site {
        siteMetadata {
          title
          description
          siteUrl
          menuLinks {
            link
            name
            items {
              link
              name
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <Header siteMetadata={data.site.siteMetadata} />
      <h1 style={{ visibility: 'hidden', display: 'none' }}>{title}</h1>
      <main style={{ marginTop: '75px' }} className="container">
        {children}
      </main>
      <Footer siteMetadata={data.site.siteMetadata} buildTime={buildTime} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  buildTime: PropTypes.string,
};

Layout.defaultProps = {
  children: {},
  title: '',
  buildTime: '',
};

export default Layout;
